import "./TimerPanel.css";
import TimerProgress from "./TimerProgress";
import { IoHeadsetOutline } from "react-icons/io5";
import { GoBook } from "react-icons/go";
import { FaMicrophone } from "react-icons/fa";
import { Container, ProgressBar } from "react-bootstrap";
function TimerPanel(props) {
    return (
        <div style={{ backgroundColor: "white", position: "fixed", top: "55px", width: "100%", zIndex: "100" }}>
            <Container>
                <div className="timerPanel">

                    {props.session === "reading" ? <GoBook size="1.5em" color="#00b9c2" /> : props.session === "listening" ? <IoHeadsetOutline size="1.5em" color="#00b9c2" /> : <FaMicrophone size="1.5em" color="#00b9c2" />}
                    <ProgressBar min={0} animated max={100} now={props.progress} animated={false} />
                    <TimerProgress time={props.time} finishTimer={props.finishTimer} />

                </div>
            </Container>
        </div>
    );
}

export default TimerPanel;