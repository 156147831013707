import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Image, Navbar, Offcanvas } from "react-bootstrap";
import "../src/App.css";
import ThanksPage from "./screens/ThanksPage";
import Logo from "./assets/images/logo-2.png";
import Survey from "./screens/Survey";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
} from "react-router-dom";

import Nav from "react-bootstrap/Nav";
function App() {
  return (
    <>
      <Navbar bg="light" expand={false} style={{ zIndex: "100" }}>
        <Container fluid>
          <Navbar.Brand href="#">
            <Image src={Logo} style={{ width: 100 }} />
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="offcanvasNavbar" /> */}
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="start"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body></Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Router>
        <Routes>
          <Route path="/" element={<Survey />} />
          <Route path="/thanks" element={<ThanksPage />} />
        </Routes>
      </Router>
      {/* <RegisterScreen /> */}
    </>
  );
}

export default App;
