import { useEffect, useState, useRef } from "react";
import * as Axios from "axios";
import TimerPanel from "../components/TimerPanel";
import {
  Button,
  Card,
  Container,
  Form,
  Row,
  Image,
  Col,
  FormControl,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { setDataStorage, getDataStorage } from "../hook/DataStore";
import "react-h5-audio-player/lib/styles.css";
import ReactLoading from "react-loading";
import SweetAlert from "react-bootstrap-sweetalert";
import "../assets/css/global.css";
import { useNavigate } from "react-router-dom";
import "../App.css";
import {
  FaSadTear,
  FaFrown,
  FaMeh,
  FaSmile,
  FaGrinStars,
} from "react-icons/fa";
// import Input from "react-bootstrap-sweetalert/dist/components/Input";

const styles = {
  rhap_container: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
};
var Api_req_url = "https://bot.jagobahasa.com/";

function Survey(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({
    show: false,
    label: "",
    type: "spinningBubbles",
  });
  const [alert, setAlertMessage] = useState({
    show: false,
    type: "success",
    label: "",
    text: "",
    confirmation: () => {},
  });
  const [kategori_kelas, set_kategori_kelas] = useState("");
  const [alasan_suka, set_alasan_suka] = useState([]);
  const [alasan_tidak_suka, set_alasan_tidak_suka] = useState("");
  const [isTextareaVisible_tidak_suka, set_isTextareaVisible_tidak_suka] =
    useState(true);
  const [saran, set_saran] = useState("");
  const [isTextareaVisible_saran, set_isTextareaVisible_saran] = useState(true);

  const handleSubmit = (e) => {
    // Data yang akan dikirim dalam permintaan POST (ubah sesuai kebutuhan)
    const postData = {
      email: Email,
      nama: Nama,
      kategori_kelas: kategori_kelas,
      alasan_suka: alasan_suka,
      alasan_tidak_suka: alasan_tidak_suka,
      saran: saran,
      nilai: score,
      kategori_form: "CSAT",
    };

    // Konfigurasi untuk permintaan POST
    // console.log(postData);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Tentukan jenis konten yang dikirim (JSON dalam contoh ini)
        // Tambahkan header lain jika diperlukan
      },
      body: JSON.stringify(postData), // Mengubah objek JavaScript menjadi string JSON
    };

    // Lakukan fetch dengan metode POST
    fetch(Api_req_url + `api/jb-survey`, requestOptions)
      .then((response) => {
        // Periksa apakah permintaan sukses (status 200 OK)
        if (!response.ok) {
          throw new Error(`Terjadi kesalahan: ${response.status}`);
        }
        // Jika sukses, ambil data respons sebagai JSON
        return response.json();
      })
      .then((data) => {
        // Lakukan sesuatu dengan data respons yang diterima
        console.log("Respon server:", data);
        if (data.status == false) {
          setAlertMessage({
            show: true,
            type: "error",
            label: "Maaf Kamu Belum Pernah Terdaftar Di Jago Bahasa",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else {
          navigate("/thanks");
        }
      })
      .catch((error) => {
        // Tangani kesalahan yang terjadi selama fetch
        console.error("Kesalahan fetch:", error.message);
      });
  };

  // kategori kelas
  const [Private, setPrivate] = useState(false);
  const [Reguler, setReguler] = useState(false);

  // suka dari jago bahasa
  const [Email, setEmail] = useState(null);
  const [ReadEmail, setReadEmail] = useState(false);
  const [Nama, setNama] = useState(null);
  const [ReadNama, setReadNama] = useState(false);

  const [SocialMedia, setSocialMedia] = useState(false);
  const [Konsultasi, setKonsultasi] = useState(false);
  const [JamBelajar, setJamBelajar] = useState(false);
  const [Keluhan, setKeluhan] = useState(false);
  const [MetodePengajaran, setMetodePengajaran] = useState(false);
  const [PenguasaanKelas, setPenguasaanKelas] = useState(false);
  const [SkillBahasa, setSkillBahasa] = useState(false);
  const [MateriBelajar, setMateriBelajar] = useState(false);
  const [FeedBack, setFeedBack] = useState(false);
  const [YangLain, setYangLain] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");

  const handleTextareaChange = (valinput) => {
    const komentar_yg_lain = valinput.replace(/[^a-zA-Z0-9.@_-\s]/g, "");
    setTextareaValue(komentar_yg_lain);
  };

  const handleClose = () => {
    const stringHapus = textareaValue;
    set_alasan_suka((prevState) =>
      prevState.filter((data) => data !== stringHapus),
    );
    setShowModal(false);
    setYangLain(false);
    setTextareaValue("");
  };
  const handleSaveChanges = () => {
    set_alasan_suka((prevState) => [...prevState, textareaValue]);
    setShowModal(false);
  };

  const [dataExamp, setDataExamp] = useState([{ type: "splash_screen" }]);

  const [score, setScore] = useState(5);

  const handleStarClick = (value) => {
    setScore(value);
  };

  const getEmoji = () => {
    switch (score) {
      case 1:
        return <FaSadTear size={60} color="#495057" />;
      case 2:
        return <FaFrown size={60} color="#6f42c1" />;
      case 3:
        return <FaMeh size={60} color="#dace1d" />;
      case 4:
        return <FaSmile size={60} color="#24c41a" />;
      case 5:
        return <FaGrinStars size={60} color="#fc4a1a" />;
      default:
        return null;
    }
  };

  let filteredEmail = "";
  let filteredNama = "";
  const collectAnswerEmail = (valinput) => {
    filteredEmail = valinput.replace(/[^a-zA-Z0-9.@_-\s]/g, "");
    // Simpan nilai input yang difilter ke dalam variabel
  };

  const collectAnswerNama = (valinput) => {
    filteredNama = valinput.replace(/[^a-zA-Z0-9.@_-\s]/g, "");
    // Simpan nilai input yang difilter ke dalam variabel
  };

  function collectPrivate(params) {
    if (params == true) {
      setReguler(false);
      //NAMA SISWA
      if (filteredNama == "" && filteredEmail == "") {
        if (Nama == null || Email == null) {
          setAlertMessage({
            show: true,
            type: "warning",
            label: "Nama atau Email tidak boleh kosong",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else {
          setReadNama(true);
          setReadEmail(true);
          setPrivate(true);
          set_kategori_kelas("PRIVATE");
        }
      } else if (Nama == null || Email == null) {
        setNama(filteredNama == "" ? Nama : filteredNama);
        setEmail(filteredEmail == "" ? Email : filteredEmail);
        setReadEmail(true);
        setReadNama(true);
        setPrivate(true);
        set_kategori_kelas("PRIVATE");
      }
    }
  }

  function collectReguler(params) {
    if (params == true) {
      setPrivate(false);
      //NAMA SISWA
      if (filteredNama == "" && filteredEmail == "") {
        if (Nama == null || Email == null) {
          setAlertMessage({
            show: true,
            type: "warning",
            label: "Nama atau Email tidak boleh kosong",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else {
          setReadEmail(true);
          setReadNama(true);
          setReguler(true);
          set_kategori_kelas("REGULER");
        }
      } else if (Nama == null || Email == null) {
        setNama(filteredNama == "" ? Nama : filteredNama);
        setEmail(filteredEmail == "" ? Email : filteredEmail);
        setReadEmail(true);
        setReadNama(true);
        setReguler(true);
        set_kategori_kelas("REGULER");
      }
    }
  }

  function SocialMedia_f(params) {
    if (params == true) {
      setSocialMedia(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Konten Social Media (Instagram/Tik Tok)",
      ]);
    } else {
      setSocialMedia(false);
      const stringHapus = "Konten Social Media (Instagram/Tik Tok)";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }

  function Konsultasi_f(params) {
    if (params == true) {
      setKonsultasi(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Pelayanan Konsultasi Program hingga Pendaftaran",
      ]);
    } else {
      setKonsultasi(false);
      const stringHapus = "Pelayanan Konsultasi Program hingga Pendaftaran";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function JamBelajar_f(params) {
    if (params == true) {
      setJamBelajar(true);
      set_alasan_suka((prevState) => [...prevState, "Pilihan Jam Belajar"]);
    } else {
      setJamBelajar(false);
      const stringHapus = "Pilihan Jam Belajar";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function Keluhan_f(params) {
    if (params == true) {
      setKeluhan(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Pelayanan Keluhan/Kendala Teknis",
      ]);
    } else {
      setKeluhan(false);
      const stringHapus = "Pelayanan Keluhan/Kendala Teknis";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function MetodePengajaran_f(params) {
    if (params == true) {
      setMetodePengajaran(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Kualitas Teacher (Metode Pengajaran)",
      ]);
    } else {
      setMetodePengajaran(false);
      const stringHapus = "Kualitas Teacher (Metode Pengajaran)";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function PenguasaanKelas_f(params) {
    if (params == true) {
      setPenguasaanKelas(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Kualitas Teacher (Penguasaan Kelas)",
      ]);
    } else {
      setPenguasaanKelas(false);
      const stringHapus = "Kualitas Teacher (Penguasaan Kelas)";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function SkillBahasa_f(params) {
    if (params == true) {
      setSkillBahasa(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Kualitas Teacher (Skill Bahasa inggris)",
      ]);
    } else {
      setSkillBahasa(false);
      const stringHapus = "Kualitas Teacher (Skill Bahasa inggris)";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function MateriBelajar_f(params) {
    if (params == true) {
      setMateriBelajar(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Kualitas Materi pembelajaran",
      ]);
    } else {
      setMateriBelajar(false);
      const stringHapus = "Kualitas Materi pembelajaran";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }
  function FeedBack_f(params) {
    if (params == true) {
      setFeedBack(true);
      set_alasan_suka((prevState) => [
        ...prevState,
        "Feedback yang progresif dan detail (weekly report dan sertifikat)",
      ]);
    } else {
      setFeedBack(false);
      const stringHapus =
        "Feedback yang progresif dan detail (weekly report dan sertifikat)";
      set_alasan_suka((prevState) =>
        prevState.filter((data) => data !== stringHapus),
      );
    }
  }

  function YangLain_f(params) {
    if (params == true) {
      setYangLain(true);
      setShowModal(true);
    } else {
      setYangLain(false);
    }
  }

  let text_alasan_tidak_suka = "";
  const collectAnswerTidakSuka = (valinput) => {
    text_alasan_tidak_suka = valinput.replace(/[^a-zA-Z0-9.@_-\s]/g, "");
  };

  let text_saran = "";
  const collectAnswerSaran = (valinput) => {
    text_saran = valinput.replace(/[^a-zA-Z0-9.@_-\s]/g, "");
  };
  function nextExamp() {
    // setDataStorage("dataExamp", "indexExamp", {
    //   type: "email",
    // });
    if (dataExamp[0].type == "splash_screen") {
      setDataExamp([{ type: "email" }]);
    } else if (dataExamp[0].type == "email") {
      if (filteredEmail == "") {
        if (Nama == null) {
          setAlertMessage({
            show: true,
            type: "warning",
            label: "Nama atau Email tidak boleh kosong",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else if (Email == null) {
          setAlertMessage({
            show: true,
            type: "warning",
            label: "Nama atau Email tidak boleh kosong",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else if (kategori_kelas == "") {
          console.log(2);
          setAlertMessage({
            show: true,
            type: "warning",
            label: "Jenis Kelas tidak boleh kosong",
            text: "",
            confirmation: () =>
              setAlertMessage((dt) => ({ ...dt, show: false })),
          });
        } else {
          const postData = {
            email: Email,
          };

          // Konfigurasi untuk permintaan POST
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Tentukan jenis konten yang dikirim (JSON dalam contoh ini)
              // Tambahkan header lain jika diperlukan
            },
            body: JSON.stringify(postData), // Mengubah objek JavaScript menjadi string JSON
          };

          // Lakukan fetch dengan metode POST
          fetch(Api_req_url + `api/cek/jb-survey`, requestOptions)
            .then((response) => {
              // Periksa apakah permintaan sukses (status 200 OK)
              if (!response.ok) {
                throw new Error(`Terjadi kesalahan: ${response.status}`);
              }
              // Jika sukses, ambil data respons sebagai JSON
              return response.json();
            })
            .then((data) => {
              // Lakukan sesuatu dengan data respons yang diterima
              if (data.status == false) {
                setAlertMessage({
                  show: true,
                  type: "error",
                  label: "Maaf Kamu Belum Pernah Terdaftar Di Jago Bahasa",
                  text: "",
                  confirmation: () =>
                    setAlertMessage((dt) => ({ ...dt, show: false })),
                });
              } else if (data.status == "sudah mengisi") {
                setAlertMessage({
                  show: true,
                  type: "error",
                  label: "Maaf Kamu Sudah Pernah Mengisi Survey Di Jago Bahasa",
                  text: "",
                  confirmation: () =>
                    setAlertMessage((dt) => ({ ...dt, show: false })),
                });
              } else {
                setDataExamp([{ type: "suka" }]);
              }
            })
            .catch((error) => {
              // Tangani kesalahan yang terjadi selama fetch
              console.error("Kesalahan fetch:", error.message);
            });
        }
      } else if (kategori_kelas == "") {
        setEmail(filteredEmail);
        setReadEmail(true);
        console.log(1);
        setAlertMessage({
          show: true,
          type: "warning",
          label: "Jenis Kelas tidak boleh kosong",
          text: "",
          confirmation: () => setAlertMessage((dt) => ({ ...dt, show: false })),
        });
      } else {
        setDataExamp([{ type: "suka" }]);
      }
    } else if (dataExamp[0].type == "suka") {
      if (alasan_suka.length == 0) {
        setAlertMessage({
          show: true,
          type: "warning",
          label: "Alasan suka tidak boleh kosong",
          text: "",
          confirmation: () => setAlertMessage((dt) => ({ ...dt, show: false })),
        });
      } else {
        setDataExamp([{ type: "tidak_suka" }]);
      }
    } else if (dataExamp[0].type == "tidak_suka") {
      if (text_alasan_tidak_suka != "") {
        set_alasan_tidak_suka(
          text_alasan_tidak_suka == ""
            ? alasan_tidak_suka
            : text_alasan_tidak_suka,
        );
        set_isTextareaVisible_tidak_suka(false);
        setDataExamp([{ type: "saran" }]);
      } else if (alasan_tidak_suka != "") {
        set_isTextareaVisible_tidak_suka(false);
        set_alasan_tidak_suka(
          text_alasan_tidak_suka == ""
            ? alasan_tidak_suka
            : text_alasan_tidak_suka,
        );
        setDataExamp([{ type: "saran" }]);
      } else {
        setAlertMessage({
          show: true,
          type: "warning",
          label: "Alasan tidak suka tidak boleh kosong",
          text: "",
          confirmation: () => setAlertMessage((dt) => ({ ...dt, show: false })),
        });
      }
    } else if (dataExamp[0].type == "saran") {
      if (text_saran != "") {
        set_saran(text_saran == "" ? saran : text_saran);
        set_isTextareaVisible_saran(false);
        setDataExamp([{ type: "puas" }]);
      } else if (saran != "") {
        set_isTextareaVisible_saran(false);
        set_saran(text_saran == "" ? saran : text_saran);
        setDataExamp([{ type: "puas" }]);
      } else {
        setAlertMessage({
          show: true,
          type: "warning",
          label: "Saran tidak boleh kosong",
          text: "",
          confirmation: () => setAlertMessage((dt) => ({ ...dt, show: false })),
        });
      }
    }
  }

  function backExamp() {
    // setDataStorage("dataExamp", "indexExamp", {
    //   type: "email",
    // });
    if (dataExamp[0].type == "email") {
      setDataExamp([{ type: "splash_screen" }]);
    } else if (dataExamp[0].type == "suka") {
      setDataExamp([{ type: "email" }]);
    } else if (dataExamp[0].type == "tidak_suka") {
      if (text_alasan_tidak_suka != "") {
        set_alasan_tidak_suka(text_alasan_tidak_suka);
        set_isTextareaVisible_tidak_suka(false);
      }
      setDataExamp([{ type: "suka" }]);
    } else if (dataExamp[0].type == "saran") {
      if (text_saran != "") {
        set_saran(text_saran);
        set_isTextareaVisible_saran(false);
      }
      setDataExamp([{ type: "tidak_suka" }]);
    } else if (dataExamp[0].type == "puas") {
      setDataExamp([{ type: "saran" }]);
    }
  }

  const isMobile = window.innerWidth <= 905; // Logika untuk menentukan apakah mode hp

  // Menyiapkan style untuk mode laptop atau pc dan mode hp
  const laptopPCStyle = {
    // paddingTop: "17px",
    // paddingBottom: "16px",
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const mobileStyle = {
    // paddingTop: "17px",
    // paddingBottom: "16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  // Menentukan style berdasarkan kondisi mode
  const containerStyle = isMobile ? mobileStyle : laptopPCStyle;

  function getResponsiveStyle_suka() {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth <= 905;
    const isTablet = screenWidth > 905 && screenWidth <= 1024; // Logika untuk menentukan apakah mode hp

    // Menyiapkan style untuk mode laptop atau pc dan mode hp
    const laptopPCStyle = {
      backgroundColor: "#ff8100",
      boxShadow: "none",
      border: "none",
      marginTop: "20px",
      height: "10vh",
    };

    const tabletStyle = {
      backgroundColor: "#ff8100",
      boxShadow: "none",
      border: "none",
      marginTop: "20px",
      width: "80%",
    };

    const mobileStyle = {
      backgroundColor: "#ff8100",
      boxShadow: "none",
      border: "none",
      marginTop: "20px",
      width: "100%",
      height: "90px",
    };

    // Menentukan style berdasarkan kondisi mode
    if (isMobile) {
      return mobileStyle;
    } else if (isTablet) {
      return tabletStyle;
    } else {
      return laptopPCStyle;
    }
  }

  const containerStyle_suka = getResponsiveStyle_suka();

  function getResponsiveStyle() {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth <= 905;
    const isTablet = screenWidth > 905 && screenWidth <= 1024;

    const laptopPCStyle = {
      marginTop: "20px",
      height: "10vh",
      backgroundColor: "#a0a0a0",
    };

    const tabletStyle = {
      marginTop: "20px",
      width: "80%",
      backgroundColor: "#a0a0a0",
    };

    const mobileStyle = {
      marginTop: "20px",
      width: "100%",
      height: "90px",
      backgroundColor: "#a0a0a0",
    };

    if (isMobile) {
      return mobileStyle;
    } else if (isTablet) {
      return tabletStyle;
    } else {
      return laptopPCStyle;
    }
  }

  const containerStyle_suka_white = getResponsiveStyle();

  var Exampview = (props) => {
    // const [answer, setAnswer] = useState(
    //   listAnswer[props.exam_data.kategori_soal][props.exam_data.soal_ke - 2],
    // );
    // setCekjawaban(answer);

    switch (dataExamp[0].type) {
      case "splash_screen":
        let list_inp_spalsh_screen = [];

        return (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Image
                src={
                  "https://i.pinimg.com/564x/c2/00/ff/c200ffcb629776136fef63dbe8905e5a.jpg"
                }
                style={{ width: 370, height: 200, margin: 20 }}
              />
            </div>

            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                <b>Dear Jagoans, Kami Butuh Kamu!</b>
              </div>
            </Card.Title>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                "Bantu kami meningkatkan kualitas pelayanan dengan mengisi
                survey
              </div>
              <div style={{ whiteSpace: "pre-wrap" }}>
                berikut, 5 Menitmu berharga buat Jagobahasa kedepan"
              </div>
            </Card.Title>
          </>
        );
        break;
      case "email":
        // console.log("tes");
        let list_inp_nama = [];
        list_inp_nama.push(
          <InputGroup
            key={"lb"}
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ width: "80%" }}
          >
            <Form.Control
              as="input"
              // style={{ width: "calc(100% - 30px)", marginRight: "10px" }}
              type="text"
              placeholder="contoh: arifin rohman"
              readOnly={ReadNama}
              value={Nama}
              required={true}
              onChange={(e) => {
                collectAnswerNama(e.target.value); // Memanggil fungsi untuk memfilter dan menyimpan nilai di variabel
              }}
            />
            <button
              className="btn btn-secondary" // Ganti kelas sesuai dengan styling yang Anda inginkan
              onClick={() => {
                setNama(null);
                setReadNama(false);
                setPrivate(false);
                setReguler(false);
                set_kategori_kelas("");
              }}
            >
              x
            </button>
          </InputGroup>,
        );
        let list_inp_email = [];
        list_inp_email.push(
          <InputGroup
            key={"lb"}
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ width: "80%" }}
          >
            <Form.Control
              as="input"
              // style={{ width: "calc(100% - 30px)", marginRight: "10px" }}
              type="email"
              placeholder="contoh: arifin@gmail.com"
              readOnly={ReadEmail}
              value={Email}
              required={true}
              onChange={(e) => {
                collectAnswerEmail(e.target.value); // Memanggil fungsi untuk memfilter dan menyimpan nilai di variabel
              }}
            />
            <button
              className="btn btn-secondary" // Ganti kelas sesuai dengan styling yang Anda inginkan
              onClick={() => {
                setEmail(null);
                setReadEmail(false);
                setPrivate(false);
                setReguler(false);
                set_kategori_kelas("");
              }}
            >
              x
            </button>
          </InputGroup>,
        );

        return (
          <>
            <Card.Title style={{ marginBottom: 60, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                <b>Silahkan isi data di bawah sesuai data pendaftaran kamu!</b>
              </div>
            </Card.Title>

            <label style={{ marginBottom: 5, fontWeight: "bold" }}>
              Masukan Nama Lengkap
            </label>
            <Row className="mt-1" style={{ justifyContent: "center" }}>
              {list_inp_nama}
            </Row>

            <label style={{ marginBottom: 5, fontWeight: "bold" }}>
              Masukan Email
            </label>
            <Row className="mt-1" style={{ justifyContent: "center" }}>
              {list_inp_email}
            </Row>

            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                Pilih kelas yang sedang kamu ikuti
              </div>
            </Card.Title>

            {Private == false ? (
              <Card.Text key={"list_key"} onClick={() => collectPrivate(true)}>
                <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "20px" }}></div>
                    <div style={{ width: "100%" }}>PRIVATE</div>
                  </div>
                </Button>
              </Card.Text>
            ) : (
              <Card.Text key={"list_key"} onClick={() => collectPrivate(false)}>
                <Button
                  style={{
                    backgroundColor: "#ff8100",
                    boxShadow: "none",
                    border: "none",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "20px" }}>
                      <FaCheck size="1.5em" color="white" />
                    </div>
                    <div style={{ width: "100%", color: "white" }}>PRIVATE</div>
                  </div>
                </Button>
              </Card.Text>
            )}

            {Reguler == false ? (
              <Card.Text key={"list_key"} onClick={() => collectReguler(true)}>
                <Button variant="secondary" style={{}}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "20px" }}></div>
                    <div style={{ width: "100%" }}>REGULER</div>
                  </div>
                </Button>
              </Card.Text>
            ) : (
              <Card.Text key={"list_key"} onClick={() => collectReguler(false)}>
                <Button
                  style={{
                    backgroundColor: "#ff8100",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "20px" }}>
                      <FaCheck size="1.5em" color="white" />
                    </div>
                    <div style={{ width: "100%", color: "white" }}>REGULER</div>
                  </div>
                </Button>
              </Card.Text>
            )}
          </>
        );
        break;
      case "suka":
        // console.log("tes");

        return (
          <>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                <b>
                  Apa yang Kamu suka dari Jago Bahasa ? (boleh pilih lebih dari
                  1)
                </b>
              </div>
            </Card.Title>

            <Row>
              <Col sm={6}>
                {SocialMedia == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => SocialMedia_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Konten Social Media (Instagram/Tik Tok)</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => SocialMedia_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Konten Social Media (Instagram/Tik Tok)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
              <Col sm={6}>
                {Konsultasi == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => Konsultasi_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>
                          Pelayanan Konsultasi Program hingga Pendaftaran
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => Konsultasi_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Pelayanan Konsultasi Program hingga Pendaftaran
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                {JamBelajar == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => JamBelajar_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Pilihan Jam Belajar</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => JamBelajar_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Pilihan Jam Belajar
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
              <Col sm={6}>
                {Keluhan == false ? (
                  <Card.Text key={"list_key"} onClick={() => Keluhan_f(true)}>
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Pelayanan Keluhan/ Kendala Teknis</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text key={"list_key"} onClick={() => Keluhan_f(false)}>
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Pelayanan Keluhan/ Kendala Teknis
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                {MetodePengajaran == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => MetodePengajaran_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Kualitas Teacher (Metode Pengajaran)</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => MetodePengajaran_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Kualitas Teacher (Metode Pengajaran)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
              <Col sm={6}>
                {PenguasaanKelas == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => PenguasaanKelas_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Kualitas Teacher (Penguasaan Kelas)</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => PenguasaanKelas_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Kualitas Teacher (Penguasaan Kelas)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                {SkillBahasa == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => SkillBahasa_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Kualitas Teacher (Skill Bahasa Inggris)</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => SkillBahasa_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Kualitas Teacher (Skill Bahasa Inggris)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
              <Col sm={6}>
                {MateriBelajar == false ? (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => MateriBelajar_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Kualitas materi pembelajaran</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text
                    key={"list_key"}
                    onClick={() => MateriBelajar_f(false)}
                  >
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Kualitas materi pembelajaran
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                {FeedBack == false ? (
                  <Card.Text
                    key={"list_key"}
                    style={{ marginTop: "5px" }}
                    onClick={() => FeedBack_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>
                          Feedback yang progresif dan detail (weekly report dan
                          sertifikat)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text key={"list_key"} onClick={() => FeedBack_f(false)}>
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Feedback yang progresif dan detail (weekly report dan
                          sertifikat)
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>

              <Col sm={6}>
                {YangLain == false ? (
                  <Card.Text
                    key={"list_key"}
                    style={{ marginTop: "5px" }}
                    onClick={() => YangLain_f(true)}
                  >
                    <Button
                      variant="secondary"
                      style={containerStyle_suka_white}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div>Mau isi sendiri kak</div>
                      </div>
                    </Button>
                  </Card.Text>
                ) : (
                  <Card.Text key={"list_key"} onClick={() => YangLain_f(true)}>
                    <Button style={containerStyle_suka}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "20px" }}>
                          <FaCheck size="1.5em" color="white" />
                        </div>
                        <div style={{ width: "100%", color: "white" }}>
                          Mau isi sendiri kak
                        </div>
                      </div>
                    </Button>
                  </Card.Text>
                )}
              </Col>
            </Row>
          </>
        );
        break;
      case "tidak_suka":
        // console.log("tes");
        let list_inp_tidak_suka = [];
        list_inp_tidak_suka.push(
          <InputGroup
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ width: "85%" }}
          >
            {isTextareaVisible_tidak_suka ? (
              <Form.Control
                as="textarea"
                style={{ height: "150px", marginTop: "20px" }}
                type="text"
                required={true}
                placeholder="Jawaban kamu"
                defaultValue={alasan_tidak_suka}
                onChange={(e) => {
                  collectAnswerTidakSuka(e.target.value);
                }}
              />
            ) : (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Form.Control
                  as="textarea"
                  style={{ height: "150px" }}
                  type="text"
                  required={true}
                  placeholder="Jawaban kamu"
                  defaultValue={alasan_tidak_suka}
                  onChange={(e) => {
                    collectAnswerTidakSuka(e.target.value);
                  }}
                />
                <Button
                  variant="outline-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                  onClick={() =>
                    set_isTextareaVisible_tidak_suka(true) ||
                    set_alasan_tidak_suka("")
                  }
                >
                  Hapus teks ❌
                </Button>
              </div>
            )}
          </InputGroup>,
        );
        return (
          <>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                Apa yang tidak Kamu suka dari Jago Bahasa? 😌
              </div>
            </Card.Title>
            <Row className="mt-1" style={{ justifyContent: "center" }}>
              {list_inp_tidak_suka}
            </Row>
          </>
        );
        break;
      case "saran":
        // console.log("tes");
        let list_inp_saran = [];
        list_inp_saran.push(
          <InputGroup
            key={"lb"}
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ width: "85%" }}
          >
            {isTextareaVisible_saran ? (
              <Form.Control
                as="textarea"
                style={{ height: "150px", marginTop: "20px" }}
                type="text"
                required={true}
                placeholder="Jawaban kamu"
                defaultValue={saran}
                onChange={(e) => {
                  collectAnswerSaran(e.target.value);
                }}
              />
            ) : (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Form.Control
                  as="textarea"
                  style={{ height: "150px" }}
                  type="text"
                  required={true}
                  placeholder="Jawaban kamu"
                  defaultValue={saran}
                  onChange={(e) => {
                    collectAnswerSaran(e.target.value);
                  }}
                />
                <Button
                  variant="outline-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: "auto",
                  }}
                  onClick={() =>
                    set_isTextareaVisible_saran(true) || set_saran("")
                  }
                >
                  Hapus teks ❌
                </Button>
              </div>
            )}
            {/* <Form.Control
              as="textarea"
              style={{ height: "150px" }}
              type="text"
              placeholder="Jawaban kamu"
              defaultValue={saran}
              onChange={(e) => {
                collectAnswerSaran(e.target.value); // Memanggil fungsi untuk memfilter dan menyimpan nilai di variabel
              }}
            /> */}
          </InputGroup>,
        );
        return (
          <>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                Berikan saran terbaik untuk Jago Bahasa versi Kamu! 😎😎😎😎
              </div>
            </Card.Title>
            <Row className="mt-1" style={{ justifyContent: "center" }}>
              {list_inp_saran}
            </Row>
          </>
        );
        break;
      case "puas":
        // console.log("tes");
        let list_inp_puas = [];
        list_inp_puas.push(
          <Form.Group
            key={"lb"}
            className="mb-3"
            controlId="exampleForm.ControlTextarea1"
            style={{ width: "100%" }}
          >
            <Form.Control
              as="textarea"
              style={{ width: "100%" }}
              type="text"
              placeholder="Jawaban kamu"
              // onChange={(val) => {
              //   collectAnswer(val.target.value);
              // }}
              oninput={(val) => {
                val.target.value = val.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*?)\..*/g, "$1");
              }}
            />
          </Form.Group>,
        );
        return (
          <>
            <Card.Title style={{ marginTop: 20, textAlign: "center" }}>
              <div style={{ whiteSpace: "pre-wrap" }}>
                Seberapa puas Kamu dengan seluruh layanan di Jago Bahasa?
              </div>
            </Card.Title>
            <Row className="mt-1" style={{ justifyContent: "center" }}>
              <div style={{ textAlign: "center", marginTop: 20 }}>
                <div style={{ fontSize: "2rem" }}>{getEmoji()}</div>
                <div style={{ fontSize: "3rem" }}>
                  {[...Array(5)].map((_, index) => {
                    const ratingValue = index + 1;
                    return (
                      <span
                        key={index}
                        style={{ cursor: "pointer", color: "#fbba04" }}
                        onClick={() => handleStarClick(ratingValue)}
                      >
                        {ratingValue <= score ? "★" : "☆"}
                      </span>
                    );
                  })}
                </div>
              </div>
            </Row>
          </>
        );
        break;
    }
  };

  return (
    <div style={{ background: "#f39631", height: "100vh" }}>
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          top: "58px",
          left: 0,
          backgroundColor: "rgb(255 255 255 / 92%)",
          flexDirection: "column",
          height: "calc(100vh - 58px)",
          width: "100vw",
          display: loading.show == true ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "30px" }}>{loading.label}</h4>
        <ReactLoading
          type={loading.type}
          color={"#fbba04"}
          width={100}
          height={100}
        />
      </div>
      <SweetAlert
        type={alert.type}
        title={alert.label}
        onConfirm={alert.confirmation}
        show={alert.show}
      >
        {alert.text}
      </SweetAlert>

      {dataExamp[0] && (
        <>
          <Container style={containerStyle}>
            <Row className="p-2">
              <Card
                className="text-center examps"
                style={{
                  backgroundColor: "#f4f7fc",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                <Card.Body
                  style={{
                    padding: "30px",
                    maxHeight: "514px",
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      boxSizing:
                        "content-box" /* Menghindari perubahan ukuran yang tak terduga */,
                    }}
                  >
                    <Exampview exam_data={dataExamp} />

                    {/* Modal */}
                    <Modal show={showModal} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Yang lain:</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <FormControl
                          as="textarea"
                          placeholder="Masukkan komentar kamu!"
                          value={textareaValue}
                          style={{ height: "150px" }}
                          onChange={(e) => {
                            handleTextareaChange(e.target.value); // Memanggil fungsi untuk memfilter dan menyimpan nilai di variabel
                          }}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Tutup
                        </Button>
                        <Button variant="primary" onClick={handleSaveChanges}>
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Card.Body>
              </Card>
            </Row>
            {dataExamp[0].type === "splash_screen" ? (
              <Row
                style={{
                  display: "flex",
                  padding: "9px",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="light"
                  style={{
                    width: "100%", // Sesuaikan lebar dengan kontainer
                    padding: "10px",
                    maxWidth: "100%", // Batasi lebar maksimal jika diperlukan
                    margin: "0 auto", // Menengahkan tombol
                  }}
                  onClick={() => {
                    nextExamp();
                  }}
                >
                  <b>START</b>
                </Button>
              </Row>
            ) : (
              <div>
                {dataExamp[0].type === "puas" ? (
                  <Row
                    style={{
                      display: "flex",
                      padding: "12px",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      variant="light"
                      style={{ width: "150px", padding: "10px" }}
                      onClick={() => {
                        // setDataExamp([{ type: "splash_screen" }]);
                        handleSubmit();
                      }}
                    >
                      Kirim
                    </Button>
                  </Row>
                ) : (
                  <Row
                    style={{
                      display: "flex",
                      padding: "12px",
                      justifyContent: "space-between", // agar tombol Next dan Previous berada pada sisi yang berlawanan
                    }}
                  >
                    <Button
                      variant="light"
                      style={{ width: "150px", padding: "10px" }}
                      onClick={() => {
                        backExamp(); // fungsi yang akan dijalankan saat tombol "Previous" diklik
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      variant="light"
                      style={{ width: "150px", padding: "10px" }}
                      onClick={() => {
                        nextExamp(); // fungsi yang akan dijalankan saat tombol "Next" diklik
                      }}
                    >
                      Next
                    </Button>
                  </Row>
                )}
              </div>
            )}
          </Container>
        </>
      )}
    </div>
  );
}

export default Survey;
